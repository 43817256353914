<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="3">
              <input
                v-model="searchInput"
                type="text"
                placeholder="Search Promocode, Company name"
                class="form-control"
              />
            </th>
            <th></th>
            <th></th>
            <th colspan="3">
              <router-link
                :to="{ name: 'client.promocode.create' }"
                v-if="currentUser.u.roles == 'admin'"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Promocode
                </div>
              </router-link>
            </th>
          </tr>
          <tr>
            <th>SN</th>
            <th scope="col">Title</th>
            <th scope="col">Company Name</th>
            <th scope="col">Promocode</th>
            <th scope="col">Valid Date</th>
            <th scope="col">Discount(%)</th>
            <th class="text-center">Status</th>
            <th
              style="width: 14%;"
              scope="col"
              v-if="currentUser.u.roles == 'admin'"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredPromoCodes.length > 0">
          <tr v-for="(item, index) in filteredPromoCodes" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.company_name }}</td>

            <td>{{ item.promo_code }}</td>
            <td>
              {{ item.valid_from | convertDateFormat }} ~
              {{ item.valid_to | convertDateFormat }}
            </td>
            <td>{{ item.discount_percentage }}</td>
            <td class="text-center">
              <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
              <i class="fa fa-check text-success" v-if="item.is_active"></i>
            </td>
            <td v-if="currentUser.u.roles == 'admin'">
              <button
                @click="editData(item.id)"
                class="btn btn-info btn-sm mr-3"
              >
                Edit
              </button>
              <button
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredPromoCodes.length < 1">
          <tr>
            <td colspan="10" class="text-center">
              No record(s) found
            </td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
        <!----><!---->
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_PROMOCODES,
  DELETE_PROMOCODE
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Promocodes",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: ""
    };
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getPromocodes", "currentUser"]),
    filteredPromoCodes() {
      let tempPromocode = this.getPromocodes;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempPromocode = tempPromocode.filter(item => {
          return (
            item.promo_code
              .toUpperCase()
              .includes(this.searchInput.toUpperCase()) ||
            item.company_name
              .toUpperCase()
              .includes(this.searchInput.toUpperCase())
          );
        });
      }
      return tempPromocode;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getPromocodesData();
  },
  filters: {
    convertDateFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    }
  },
  methods: {
    deleteItem(id, index) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover !",

        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_PROMOCODE, id).then(data => {
            this.$toastr.s(data.msg);
            this.getPromocodes.splice(index, 1);
          });
        }
      });
    },
    editData(id) {
      this.$router.push({ name: "client.promocode.edit", params: { id: id } });
    },
    getPromocodesData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_PROMOCODES)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>

<style></style>
